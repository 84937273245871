.ant-modal-root {
	top: 0;
    position: fixed;
    margin: auto;
    height: 100%;
    padding-top: 20%;
    text-align: center;
    width: 100%;
    background-color: rgba(0, 0, 0, .9);
	.ant-modal-close {
		display: none;
	}
	.ant-modal-footer {
		display: none;
	}
	.ant-modal {
		width: 100% !important;
	}
	.closeModal {
		background: white;
		width: 250px;
		margin: -0.5rem auto;
		text-align: left;
		height: 25px;
	}
}
ul{
	list-style: none;
}
.m3-005,.m3-006{
	.text-top{
		display: none !important;
	}
}
.hayhar-page{
	.containerMobileLogin {
		width: 100%;
		text-align: center;
		justify-content: center;
		display: flex;
		.mobileLoginModal {
			top: 30%;
			width: 75%;
			background-color: #FFF;
			overflow: hidden;
			position: fixed;
			border-radius: 0.5rem;
			.header{
				margin: 2rem 0.5rem 1rem 0.5rem;
				background-color: #FFF;
				color: #0EA5E9;
				font-size: 22px;
				font-weight: bold;
				padding: 0 1rem;
				display: flex;
				align-items: baseline;
				justify-content: space-between;
			}
			.body{
				padding: 0 1rem;
				.forgetPw {
					input {
						width: 80% !important;
						border-radius: 0.25rem 0rem 0rem 0.25rem;
					}
					.ant-input-group-addon {
						width: 19%;
						display: inline-block;
						padding: 0.51rem 0rem;
						background: #f1f2f4;
						font-size: 13px;
					}
				}
				input {
					border: 1px solid #edeef1 !important;
					width: 100%;
					margin-bottom: 0.5rem;
				}
			}

			.btn{
				width: 86%;
				margin: 0 auto;
				margin-bottom: 1.5rem;
				margin-top: 0.5rem;
				height: 2rem;
				line-height: 2rem;
				border-radius: 0.25rem;
				background-color: #0ea5e9;
				color: #FFF;
				cursor: pointer;
			}
		}
	}

	.text-top{
		display: none !important;
	}
	.ant-carousel{
		height: 730px;
		.slick-list{
			.slick-track{
				display: flex;
			}
			.img_wrap{
				img{
					// object-fit:initial !important;
				}
			}
		}
	}
	position: relative;
	.img_wrap{
		img{
			object-fit:cover;
			height: 100%;
			width: 100%;
			vertical-align: middle;
		}
		height: 730px;
	}
	.mobile{
		display:none;

		&.top{
			display: none;
		}

	}
	overflow: hidden;
	input{
		height: 2rem;
		border-radius:.25rem;
		padding: .25rem .3rem;
		box-sizing: border-box;
		outline-style:none;
		outline-width: 0px;
		border:2px solid transparent;
		text-shadow: none;
		-webkit-appearance:none;
		-webkit-user-select:text;
		outline-color: transparent;
		box-shadow: none;
		text-overflow:ellipsis;
	}
	.top{
		padding: 1em;
		background-color: rgb(56, 189, 248);
		display: flex;
		justify-content: space-between;
		align-items: center;
		.qrp-header-logo{
			width: 150px;
		}

		// 输入框
		.web-input-box{
			position: relative;
			display: flex;
			align-items: center;
			.web-input{

				&:focus{
					box-shadow: 0 0, #93c5fd;
					border-color:#93c5fd;

				}
			}
			.web-input+.web-input{
				margin-left: .5rem;
				width: 15rem;
				padding-right: 5rem;
				// margin-right: 5rem;
			}
			.web-label{
				position: absolute;
				top: .5rem;
				right:10.6rem;
				color: inherit;
				text-decoration:inherit;
				font-size: .75rem;
				line-height: 1rem;
				cursor: pointer;
				padding-left: .3rem;
				color: rgba(56,189,248,1);
				border-left: 1px solid rgba(229,231,235,1);
				&:hover{
					color: rgba(56,189,248,1);
				}
			}

			// 登录按钮
			.web-login{
				padding: 0.25rem 1rem;
				margin-left: .3rem;
				background-color: #FFF;
				border:none;
				height: 2rem;
				border-radius: .25rem;
				border: 1px solid transparent;
				&:hover{
					border-color: #FFF;
					background-color: rgb(56, 189, 248);
					color: #FFF;
				}
			}
			.country-icon{
				margin-left: .6rem;
				border-left: 1px solid #FFF;
				padding-left: .6rem;
			}

		}
	}

	.ant-input{
		color: #000;
	}

	.error-hit{
		display: flex;
		margin-bottom: 1rem;
		.phone-hit-error {
			margin-left: 22%;
		}
		p{
			color: #ff0000;
			width: 50%;
			text-align: left;
			font-size: 12px;
		}
	}

	// 中间内容
	.central-web{
		// background: url(/static/images/hayhar/QRP-NUFC-Desktop.jpg) no-repeat center ;
		// background-size:100%;
		overflow: hidden;
		// position: relative;
		.text-top{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: .8rem auto;
			position: absolute;
			top: 4.55rem;
			left: 30%;
			h2{
				font-size: 3rem;
			}
			p{
				font-size: 2rem;
			}
		}
		.central-box{
			// position: absolute;
			border-radius: .5rem;
			overflow: hidden;
			background-color: #FFF;
			margin: 5% 7rem 0 0%;
			width: 27rem;
			position: absolute;
			top: 12rem;
			right: 0;
			.tips{
				color:#9ca3af ;
				margin: .75rem auto 0;
				text-align: center;

			}
			.heading{
				padding:1rem 0;
				background-color: rgb(56, 189, 248);
				text-align: center;
				color: #FFF;
			}
			.form-modal{
				padding: 1.25rem;
				padding-bottom: 0;
				input{
					border: 1px solid rgb(209,213,219);
					-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(209,213,219 .6);
					box-shadow: inset 0px 1px 1px rgba(0, 0, 0, .075) , 0px 0px 8px rgba(209,213,219 .6);
					color:#9ca3af ;
					&:focus{
						border:2px solid rgb(56, 189, 248);
					}
				}
				.namePass{
					display: flex;
					justify-content: space-between;
					margin-bottom: 1rem;
					input{
						width: 49%;
					}
				}
				.email-input{
					display: flex;
					margin-bottom: 1rem;
					input{
						width: 100%;
					}

				}
				.phone-number{
					display: flex;
					justify-content: space-between;
					input.phone{
						width: 20%;
						color: #9ca3af;
						cursor: no-drop;
					}
					input.phoneNumber{
						width: 78%;
					}
				}
			}
			.submit{
				margin: 1rem auto;
				padding: 8px 16px;
				display: flex;
				justify-content: center;
				background-color: #0ea5e9;
				border: none;
				border-radius: .25rem;
				color: #FFF;
				font-size: .95rem;
				&:hover{
					background-color: rgb(56,189,248);
				}
			}
		}
	}
	// 底部内容
	.footer-content-web{
		.partner{
			padding: 1rem;
			background-color: rgb(209,213,219,);
			display: flex;
			flex-wrap: wrap;
			.item{
				width: 33.333333%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.title{
					margin-bottom: 1.25rem;
				}
				.image-box{
					display: flex;
					justify-content: space-between;
					& > div{
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						& +div{
							margin-left: 10px;
						}
					}

					& .default{
						height: 5rem;
						width: 3rem;
					}
					.footer-image-box-text{
						display: flex;
						flex-direction: column;
						font-size: .75rem;
						text-align: center;
						a{
							color: #000;
						}
					}
					.partner-item-0{
						background: url('/static/images/hayhar/tottenham.png') no-repeat center;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/tottenham-hover.png') no-repeat center;
							background-size: cover;
						}
					}
					.parrtner-item-1{
						background: url('/static/images/hayhar/newcastle.png') no-repeat center;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/newcastle-hover.png') no-repeat center;
							background-size: cover;
						}
					}

				}
			}
			// 品牌形象大使
			.brand {
				.brand-default{
					width: 16.25rem;
					height: 9.375rem;
				}
				.brand-item-0 {
					background: url(/static/images/hayhar/Iker.png) no-repeat center center;
					background-size: cover;
					&:hover{
						background: url(/static/images/hayhar/Iker-hover.png) no-repeat center center;
						background-size: cover;
					}
				}
			}
			// 供应商
			.suppliers{
				.suppliers-public-style{
					margin-top:calc(1rem*(1 - 0));
					margin-right: calc(0.75rem*0);
					margin-left: calc(0.75rem*(1 - 0));
				}
				.suppliers-box{
					display: flex;
					justify-content: center;
					flex-wrap:wrap;
				}
				.suppliers-default{
					width: 4rem;
					height: 2.25rem;
				}
				.suppliers-item-0{
					background: url('/static/images/hayhar/AG_Unhover.png')no-repeat center;
					background-size: cover;
					margin-top: 1rem;
					&:hover{
						background: url('/static/images/hayhar/AG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-1{
					background: url('/static/images/hayhar/BTI_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/BTI_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-2{
					background: url('/static/images/hayhar/CMD_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/CMD_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-3{
					background: url('/static/images/hayhar/eBet_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/eBet_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-4{
					background: url('/static/images/hayhar/EVO_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/EVO_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-5{
					background: url('/static/images/hayhar/GPI_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/GPI_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-6{
					background: url('/static/images/hayhar/IM_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/IM_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-7{
					background: url('/static/images/hayhar/JILI_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/JILI_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-8{
					background: url('/static/images/hayhar/Joker_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/Joker_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-9{
					background: url('/static/images/hayhar/KingPoker_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/KingPoker_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-10{
					background: url('/static/images/hayhar/KM_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/KM_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-11{
					background: url('/static/images/hayhar/Microgaming_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/Microgaming_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-12{
					background: url('/static/images/hayhar/PG_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/PG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-13{
					background: url('/static/images/hayhar/Playtech_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/Playtech_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-14{
					background: url('/static/images/hayhar/PNG_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/PNG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-15{
					background: url('/static/images/hayhar/PP_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/PP_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-16{
					background: url('/static/images/hayhar/SABA-SEA_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/SABA-SEA_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-17{
					background: url('/static/images/hayhar/SPalace_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/SPalace_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-18{
					background: url('/static/images/hayhar/Spribe_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/Spribe_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-19{
					background: url('/static/images/hayhar/TCGaming_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/TCGaming_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-20{
					background: url('/static/images/hayhar/TFG_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/TFG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-21{
					background: url('/static/images/hayhar/V2_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/V2_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-22{
					background: url('/static/images/hayhar/WMCasino_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/WMCasino_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
			}
		}
		/* 版权、 pay 、安全、责任*/
		.copyright-pay-safe{
			clear:both;
			display: flex;
			padding: .5rem;
			background-color: rgba(229,231,235,1);
			box-sizing: border-box;
			justify-content: center;
			h6{
				white-space: nowrap;
			}
			.copyright,.safe,.responsibillity{
				width: 16.666667%;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				&.copyright .icon{
					width: 1.6875rem;
					height: 2.0625rem;
					margin-left: 0.625rem;
					background: url(/static/images/hayhar/first-cagayan.png) no-repeat 0 0;
					background-size: cover;
					margin: 0 .25rem;
					&:hover{
						background: url(/static/images/hayhar/first-cagayan-hover.png) no-repeat 0 0;
						background-size: cover;
					}
				}
				&.safe .icon{
					width: 5.375rem;
					height: 2.0625rem;
					background: url(/static/images/hayhar/iovation.png) no-repeat 0 0;
					background-size: cover;
					margin-left: 0.625rem;
					&:hover{
						background: url(/static/images/hayhar/iovation-hover.png) no-repeat 0 0;
						background-size: cover;
					}
				}
				&.responsibillity>div{
					display: flex;
					flex-wrap: wrap;
				}
				&.responsibillity .icon{
					width: 2.0625rem;
					height: 2.0625rem;
					margin-left: 0.625rem;
					&.icon.icon-1{
						background: url('/static/images/hayhar/game-card.png') no-repeat 0 0;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/game-card-hover.png') no-repeat 0 0;
							background-size: cover;
						}
					}
					&.icon.icon-2{
						background: url('/static/images/hayhar/up-21.png') no-repeat 0 0;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/up-21-hover.png') no-repeat 0 0;
							background-size: cover;
						}
					}
				}
			}


			// 付款方式
			.bank-pay{
				display: flex;
				flex-grow:1;
				align-items: center;
				flex-wrap: wrap;
				.icon{
					width: 4.125rem;
					height: 2.0625rem;
					margin: 0 0.3125rem;
				}
				.icon-1{
					background: url('/static/images/hayhar/easypay.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/easypay-hover.png') no-repeat 0 0;
						background-size: cover;
					}
				}
				.icon-2{
					background: url('/static/images/hayhar/localbank.png') no-repeat 0 0;
					width: 3.6875rem;
					height: 2.0625rem;
					&:hover{
						background: url('/static/images/hayhar/localbank-hover.png') no-repeat 0 0;
					}
				}
				.icon-3{
					background: url('/static/images/hayhar/fastbaht.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/fastbaht-hover.png') no-repeat 0 0;
					}
					width: 5.375rem;
				}
				.icon-4{
					width: 2.4375rem;
					height: 2.0625rem;
					background: url('/static/images/hayhar/line.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/line-hover.png') no-repeat 0 0;
					}
				}
				.icon-5{
					width: 4.1875rem;
					background: url('/static/images/hayhar/cashcard.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/cashcard-hover.png') no-repeat 0 0;
					}
				}
				.icon-6{
					width: 4.5rem;
					background: url('/static/images/hayhar/qrcode.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/qrcode-hover.png') no-repeat 0 0;
					}
				}
			}
		}

		.montenegro{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: .5rem;
			background: #f3f4f6;
			img{
				margin: 10px auto;
			}
			p{
				margin-bottom: 0.625rem;
				&.text-gray-500{
					color: rgb(107,114,128,);
					font-size: .75rem;
					text-align: center;
					width: 56%;
				}
			}
		}


		// end-page
		.end-page{
			background-color: rgb(14,165,233);
			padding: 1rem;
			.end-page-list{
				display: flex;
				justify-content: center;
				font-size: .875rem;
				color: #FFF;
				.item{
					margin: 0 1.25rem 0 0;
					cursor: pointer;
				}
			}
			.help-contact{
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 2.725rem;
				.help{
					display: flex;
					align-items: center;
					color: #FFF;
					cursor: pointer;
					img{
						margin-right: 0.5rem;
					}
					span{
						border-right: 1px solid #FFF;
						padding-right: 15px;
					}
				}
				.contact-box{
					display: flex;
					align-items: center;
					margin-left: 15px;

					.item{
						width: 2.5rem;
						height: 33.7px;
						margin-right:15px;
						cursor: pointer;
						&:nth-child(1){
							background: url('/static/images/hayhar/footer-telegram.png');
							background-size: cover;
							&:hover{
								background: url('/static/images/hayhar/footer-telegram-hover.png');
								background-size: cover;
							}
						}
						// &:nth-child(3){
						// 	background: url('/static/images/hayhar/footer-twitter.png');
						// 	background-size: cover;
						// 	&:hover{
						// 		background: url('/static/images/hayhar/footer-twitter-hover.png');
						// 		background-size: cover;
						// 	}
						// }
					}
					.country{
						width: 2rem;
						height: 1.375rem;
						border-left: 1px solid #FFF;
						padding-left:15px ;
						cursor: auto;
					}
				}
			}
			.end-page-footer-text{
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				p{
					color: #FFF;
					margin-top: 0.9375rem;
					font-size: .725rem;
				}
			}
			.seoHeader {
				margin: 2rem auto;
				color: #035789;
				font-size: 18px;
				text-align: center;
				p {
					margin-top: 1rem;
					font-size: 16px;
					width: 80%;
					text-align: left;
					margin: 1rem auto;
				}
			}
			.seoBody {
				margin: 2rem auto;
				color: #035789;
				font-size: 18px;
				text-align: center;
				p {
					margin-top: 1rem;
					font-size: 16px;
					width: 80%;
					text-align: left;
					margin: 1rem auto;
				}
			}
		}
	}
	.footer-content-web.v2of2023{
		.footer-top-Wrapper{
			.footer-first-wrapper{
				padding-top:16px;
			}
			.sponsor-logo{
				margin:0;
			}
		}
		.partner {
			background-color: #fff;
			display: block;
			.item {
				width: 30%;
				float:left;
				display: block;
			}
			.vendors-item {
				width: 40%;
			}
		}
		.sponsor-item{
			a{
				text-decoration: none;
			}
		}
	}
	.footer-content-web.v2of2023::after{
		content:'';
		visibility: hidden;
		height:0;
		width:100%;
		clear:both;
		display:block;
	}


	// 社交按钮
	.social-web{
		position: fixed;
		transform: translate(50%, -50%);
		top: 48%;
		right: -1%;
		.social-item:nth-child(2){
			position: relative;
		}
		.social-item{
			text-decoration: inherit;
			cursor: pointer;
			display: flex;
			background-color: rgb(2,132,199);
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			margin: .5rem 0;
			box-shadow: 0 0 #000 , 0 0 #0000 , 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
			padding:1rem;
			white-space:nowrap;
			color: #FFF;
			width: 100%;
			&:hover{
				transform: translate(-90px,0);
				transition-duration:.3s;
			}
			img{
				height: 2.5rem;
				margin-right: .75rem;
			}
		}

		.qrcode{
			position: absolute;
			top: 4.5rem;
			background: #FFF;
			z-index: 999;
			display: flex;
			align-items: center;
			flex-direction: column;
			width: 150px;
			height: 30px;
			padding-top: 10px;
			p{
				color: #2199e8;
			}
			img{
				height: 100px;
				width: 100px;
				margin-top: 1rem;
				margin-right: 0;
			}
		}
	}
	.modal{
		position: fixed;
		width: 100%;
		z-index: 1999;
		text-align: center;
		height: 100%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		left: 0;
		top: 0;
		-webkit-transform: translateZ(1px);
		transform: translateZ(1px);
		background-color: rgba(0, 0, 0, 0.4);

		.modal-box{
			position: fixed;
			max-width: 50%;
			width: auto;
			left: 50%;
			top: 50%;
			-webkit-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
			border-radius: .5rem;
			min-width: 400px;
			background-color: #FFF;
			overflow: hidden;
			.header{
				padding: 1.25rem;
				background-color:rgb(14,165,233);
				color: #FFF;
			}
			.center{
				padding: 1.25rem;
			}

			.btn{
				width: 5rem;
				margin: 0 auto;
				margin-bottom: 1.25rem;
				padding: .25rem;
				border-radius: .25rem;
				background-color:rgb(14,165,233) ;
				color: #FFF;
				cursor: pointer;
			}
		}

	}

}

@import url('./mobile.less');